import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import startCase from 'lodash/startCase'
import find from 'lodash/find'
import filter from 'lodash/filter'
import snakeCase from 'lodash/snakeCase'
import upperCase from 'lodash/upperCase'
import isEmpty from 'lodash/isEmpty'

// Base Page
import BasePage from '@/pages/Base'

// Config
import config from '@/configs'

// Services
import OrdersService from '@/services/Orders'
import StatusService from '@/services/Status'
import CountryService from '@/services/Country'
import UserSettingsService from '@/services/UserSettings'
import { sortBy } from 'lodash'

@Component({})
export default class Orders extends BasePage {
  constructor() {
    super()
  }

	// BaseURL
	baseUrl = config.api.baseURL

	// Filter
	filterLoading: boolean = false
	filterLabel: string = 'Filter by Status'
	filterHint: string = 'Apply Status Filters Here'
	filterItems: any = {
	  items: []
	}
	filterIcon: string = 'mdi-filter-variant'
	filterSelected: string = ''

	// Filter Remark
	filterRemarkLoading: boolean = false
	filterRemarkLabel: string = 'Filter by Remark'
	filterRemarkHint: string = 'Apply Remark Filters Here'
	filterRemarkItems: any = {
	  items: []
	}
	filterRemarkIcon: string = 'mdi-filter-variant'
	filterRemarkSelected: string = ''

	// filter 
	filterByCountryProducts: any = {
	  items: [],
	  selected: '',
	  loading : false
	}

	filterByUserName: any = {
	  items: [],
	  selected: '',
	  loading : false,
	  keyword : null
	}

	// Search
	searchLoading: boolean = false
	searchInput: string = ''
	searchLabel: string = 'Search ID here...'
	searchHint: string = 'Search ID Here'
	searchIcon: string = 'search'
	searchLoadingCustomerName: boolean = false
	searchInputCustomerName: string = ''
	searchLabelCustomerName: string = 'Customer Name'
	searchHintCustomerName: string = 'Search by Customer Name'
	searchIconCustomerName: string = 'search'
	searchLoadingAgentName: boolean = false
	searchInputAgentName: string = ''
	searchLabelAgentName: string = 'Agent Name'
	searchHintAgentName: string = 'Search by Agent Name'
	searchIconAgentName: string = 'search'

	// Table
	tableHeaders: object[] = [
	  {
	    text: 'ID',
	    align: 'center',
	    sortable: true,
	    value: 'order_code'
	  },
	  {
	    text: 'Platform',
	    align: 'center',
	    sortable: false,
	    value: 'platformName'
	  },
	  {
	    text: 'Order Date',
	    align: 'left',
	    sortable: true,
	    value: 'created_at'
	  },
	  {
	    text: 'Customer Name',
	    align: 'left',
	    sortable: false,
	    value: 'receiverName'
	  },
	  {
	    text: 'User',
	    align: 'center',
	    sortable: false,
	    value: 'user.full_name'
	  },
	  {
	    text: 'Agent',
	    align: 'center',
	    sortable: true,
	    value: 'agent.name'
	  },
	  {
	    text: 'Country Products',
	    align: 'center',
	    sortable: true,
	    value: 'origin'
	  },
	  {
	    text: 'Price Total',
	    align: 'left',
	    sortable: true,
	    value: 'grand_total'
	  },
	  {
	    text: 'Payment tatus', //the previous name was "Status"
	    align: 'center',
	    sortable: true,
	    value: 'status'
	  },
	  {
	    text: 'Order Status', // the previous name was "Remark"
	    align: 'left',
	    sortable: false,
	    value: 'remark'
	  },
	  {
	    text: 'Tracking Code',
	    align: 'center',
	    sortable: false,
	    value: 'trackingCode'
	  },
	  {
	    text: 'Action',
	    align: 'center',
	    sortable: false,
	    value: 'action',
	    class: 'action-table-width'
	  }
	]
	tableItems: object[] = []
	tableTotalItems: number | string = 0
	tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
	tablePagination: any = {
	  sortBy: 'created_at',
	  page: 1,
	  rowsPerPage: 10,
	  descending: true,
	  totalItems: 0
	}
	tableLoading: boolean = false

	// Sync
	syncCustomerOrderDialog: boolean = false
	syncCustomerOrderId: string = ''
	maxSyncCustomerOrderCharacter: number = 25

	// Tracking
	trackingCode: string = ''

	selectedOrders: any = null
	selectedDelete: any = null
	showDeleteModal: boolean = false
	statuses_id: number[] = []

	@Watch('tablePagination')
	onChanged() {
	  this.$vuetify.goTo(0)
	  this.getPaymentStatus()
	  this.getRemarkStatus()
	  this.getCountryShipTo()
	  this.getOrders()
	  this.getUserName()
	}

	@Watch('filterByUserName.keyword')
	onChangedFilterUserName(){
	  this.getUserName()
	}

	/**
	 * [getMembershipUserDetail description]
	 * @param {[number]} user_id [user id]
	 */
	// async getMembershipUserDetail(user_id) {
	// 	const responseUser = await UserSettingsService.getOneUser(user_id)
	// 	var membership_include_id = responseUser.data.data.relationships.memberships[0].id
	// 	var memberships = responseUser.data.included.memberships[membership_include_id].attributes.name

	// 	return responseUser
	// }

	onSearch(){
	  this.$vuetify.goTo(0)
	  this.getOrders()
	}

	onReset(){
	  this.filterByCountryProducts.selected = ''
	  this.filterSelected = ''
	  this.filterRemarkSelected = ''
	  this.filterByUserName.selected = ''
	  this.searchInput = ''
	  this.searchInputCustomerName = ''
	  this.searchInputAgentName = ''
	  this.$vuetify.goTo(0)
	  this.getOrders()
	}

	async getUserName(){
	  this.filterByUserName.loading = true

	  const opts : any = []

	  if (!isEmpty(this.filterByUserName.keyword)){
	    opts.params = {
	      'filter[name][like]' : this.filterByUserName.keyword
	    }
	  }

	  await UserSettingsService.getUser(opts)
	    .then(response => {
	      for (const data of response.data.data){
	        this.filterByUserName.items.push(data.attributes)
	      }
	    })

	  this.filterByUserName.loading = false
	}

	async getCountryShipTo() {
	  try{
	    this.filterByCountryProducts.loading = true

	    const opts : any = {
	      params: {
	        'page[num]': 1,
	        'page[limit]': 253 
	      }
	    }

	    const response = await CountryService.getCountries(opts)
			
	    this.filterByCountryProducts.items = []
	    for (const country of response.data.data)
	      this.filterByCountryProducts.items.push(country.attributes)
			
	    this.filterByCountryProducts.items = sortBy(this.filterByCountryProducts.items, 'name')
	  } catch(error){
	    this.catchHandler(error)
	  } finally {
	    this.filterByCountryProducts.loading = false
	  }
	}

	async getPaymentStatus() {
	  try {
	    const opts: any = {
	      params: {
	        'filter[status_type][is]': 1
	      }
	    }

	    const response = await StatusService.getOrderStatus(opts)
	    const paymentStatus = response.data

	    for (const dataStatus of paymentStatus) {
	      const status: any = {
	        text: this.toStartCase(dataStatus.attributes.name),
	        value: dataStatus.attributes.id
	      }
	      this.filterItems.items.push(status)
	    }

	  } catch (error) {
	    this.catchHandler(error)
	  }
	}

	async getRemarkStatus() {
	  try {
	    const opts: any = {
	      params: {
	        'filter[status_type][is]': 2
	      }
	    }

	    const response = await StatusService.getOrderStatus(opts)
	    const remarkStatus = response.data

	    for (const dataStatus of remarkStatus) {
	      const status: any = {
	        text: this.toStartCase(dataStatus.attributes.name),
	        value: dataStatus.attributes.id
	      }
	      this.filterRemarkItems.items.push(status)
	    }

	  } catch (error) {
	    this.catchHandler(error)
	  }
	}

	async getOrders() {
	  this.tableLoading = true
	  const opts: any = {
	    params: {
	      'page[num]': this.tablePagination.page,
	      'page[limit]': this.tablePagination.rowsPerPage,
	      sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
	      include: 'payments,statuses,user,package,agent'
	    }
	  }

	  if(this.filterSelected != '' || this.filterRemarkSelected != ''){
	    this.statuses_id = []
			
	    if (this.filterSelected != '' && this.filterRemarkSelected != '') {
	      opts.params = {
	        ...opts.params,
	        'filter[statuses.id][is]': this.filterSelected+','+this.filterRemarkSelected
	      }
	    }else if (this.filterSelected != '') {
	      opts.params = {
	        ...opts.params,
	        'filter[statuses.id][is]': this.filterSelected
	      }
	    }else if (this.filterRemarkSelected != '') {
	      opts.params = {
	        ...opts.params,
	        'filter[statuses.id][is]': this.filterRemarkSelected
	      }
	    }
	  }

	  if (this.filterByCountryProducts.selected != '')
	    opts.params = {
	      ...opts.params, 
	      'filter[shipment_from][is]': this.filterByCountryProducts.selected
	    }

	  if (this.searchInput != '') {
	    opts.params = {
	      ...opts.params,
	      'filter[order_code][like]': this.searchInput
	    }
	  } else {
	    var textFile = 'filter[order_code][like]'
			
	    delete opts.params[textFile]
	  }
		
	  if (this.searchInputCustomerName != '') {
	    opts.params = {
	      ...opts.params,
	      // 'filter[customer_name][like]': this.searchInputCustomerName
	      'filter[order_infos][like]': this.searchInputCustomerName
	    }
	  } else {
	    var textFile = 'filter[customer_name][like]'
	    delete opts.params[textFile]
	  }

	  if (this.searchInputAgentName != '') {
	    opts.params = {
	      ...opts.params,
	      'filter[agent.name][like]': this.searchInputAgentName
	    }
	  } else {
	    var textFile = 'filter[agent.name][like]'
	    delete opts.params[textFile]
	  }

	  if (this.filterByUserName.selected != ''){
	    opts.params = {
	      ...opts.params,
	      'filter[user_id][is]' : this.filterByUserName.selected
	    }
	  }

	  try {
	    const response = await OrdersService.getOrders(opts)
	    this.tableTotalItems = response.meta.pagination.total
	    this.tableItems = []
	    const responseIncluded = response.included
	    forEach(response.data, dataOrder => {
	      var tc_code = '-'
	      var tc_code_courier = '-'
	      if (!isEmpty(dataOrder.relationships.package)) {
	        if (responseIncluded.package[dataOrder.relationships.package[0].id].attributes.tracking_code !== null) {
	          tc_code = responseIncluded.package[dataOrder.relationships.package[0].id].attributes.tracking_code
	        }
	        if (responseIncluded.package[dataOrder.relationships.package[0].id].attributes.tracking_code_courier !== null) {
	          tc_code_courier = responseIncluded.package[dataOrder.relationships.package[0].id].attributes.tracking_code_courier
	        }
	      }

	      // set username ALI of buyer
	      var set_userName = response.included.user[dataOrder.relationships.user.id].attributes.name

	      // get status order
	      var status_order = ''
	      var remark_status = ''
	      for (const dataStatus of dataOrder.relationships.statuses) {
	        var id_status = dataStatus.id
	        if (response.included.statuses[id_status].attributes.type == 1) {
	          status_order = response.included.statuses[id_status].attributes.name
	        } else if (response.included.statuses[id_status].attributes.type == 2) {
	          remark_status = startCase(response.included.statuses[id_status].attributes.name)
	        }
	      }

	      //set order code
	      var set_order_code = dataOrder.attributes.order_code
	      if (dataOrder.attributes.platform_id > 0) {
	        set_order_code = '#' + dataOrder.attributes.order_infos.order_id
	      }
	      //set platform name
	      // sini yogie
	      var set_platform_name = '-'
	      var set_platform_url = '-'
	      var platformIsDeleted = false
	      if (dataOrder.attributes.platform_id > 0) {

	        if (typeof response.included.platform[dataOrder.attributes.platform_id] === 'undefined') {
	          set_platform_name = 'Platform Deleted'
	          set_platform_url = '-'
	          platformIsDeleted = true
	        } else {
	          set_platform_name = response.included.platform[dataOrder.attributes.platform_id].attributes.name
	          set_platform_url = response.included.platform[dataOrder.attributes.platform_id].attributes.url
	        }

	        // for check if platform is deleted
	        // if (dataOrder.attributes.platform_id === 318) {
	        // 	set_platform_name = 'Platform Deleted'
	        // 	set_platform_url = '-'
	        // 	platformIsDeleted = true
	        // }

	      }
	      var link = ''
	      if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
	        link = 'http://alidev.asiacommerce.net/tracking/' + tc_code
	      }
	      else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/') {
	        link = 'http://ali.asiacommerce.net/tracking/' + tc_code
	      }
	      let agentName = dataOrder.attributes.ordered_by_user_id != null ? response.included.agent[dataOrder.attributes.ordered_by_user_id].attributes.name : '-'
	      // console.log(dataOrder.attributes.order_infos.shipping.first_name)
	      let order: any = {
	        ...dataOrder,
	        id: dataOrder.attributes.id,
	        order_code: set_order_code,
	        orderId: dataOrder.attributes.order_id,
	        chinaOrderId:
						dataOrder.attributes.china_order_id === null
						  ? '-'
						  : dataOrder.attributes.china_order_id,
	        // userName: dataOrder.user.full_name,
	        userName: set_userName,
	        agentName: agentName,
	        // customerName: dataOrder.attributes.customer_name,
	        customerName: '-',
	        orderDate: dataOrder.attributes.created_at,
	        platformName: set_platform_name,
	        platformUrl: set_platform_url,
	        platformIsDeleted: platformIsDeleted,
	        // old
	        // totalPrice: `${upperCase(
	        // 	dataOrder.attributes.currency
	        // )} ${this.formatMoney(
	        // 	Number(dataOrder.attributes.cost) +
	        // 	Number(dataOrder.attributes.logistic_cost)
	        // )}`,

	        totalPrice: `${upperCase(
	          dataOrder.attributes.currency
	        )} ${this.formatMoney(
	          Number(dataOrder.attributes.grand_total)
	        )}`,
	        // source: dataOrder.source,
	        // source: 'no-source',
	        trackingCode: tc_code,
	        trackingCourier: tc_code_courier,
	        remark: remark_status,
	        syncOrderDialog: false,
	        syncOrderId: '',
	        maxSyncOrderCharacter: 25,
	        actionFab: false,
	        trackingDialog: false,
	        // status: lowerCase(
	        // 	dataOrder.attributes.status === 'on-hold'
	        // 		? 'Unpaid'
	        // 		: dataOrder.attributes.status
	        // ),
	        status: status_order,
	        linkTracking: link,
	        paidDate:
						dataOrder.attributes.paid_date === null
						  ? ''
						  : this.toMediumDate(dataOrder.attributes.paid_date),
	        country: dataOrder.attributes.origin
	      }
	      // console.log(dataOrder, 'data order');
				
	      if (dataOrder.attributes.order_infos.shipping.first_name || dataOrder.attributes.order_infos.shipping.last_name) {
	        order.customerName =  dataOrder.attributes.order_infos.shipping.first_name + ' ' + dataOrder.attributes.order_infos.shipping.last_name
	      }

	      this.tableItems.push(order)
	      // console.log(this.tableItems, 'items');
	    })
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.tableLoading = false
	  }
	}

	cancelSyncOrder(validationScope: string, props: any) {
	  props.item.syncOrderDialog = false
	  this.$validator.errors.clear(validationScope)
	}

	async syncOrder(validationScope: string, props: any) {
	  try {
	    const validationResponse = await this.$validator.validateAll(
	      validationScope
	    )
	    if (validationResponse) {
	      props.item.syncOrderDialog = false
	      this.showLoading({ text: 'Syncing Order...' })
	      const response: any = await OrdersService.syncSpecificOrder(
	        props.item.id,
	        {
	          china_order_id: this.syncCustomerOrderId
	        }
	      )
	      this.getOrders()
	      this.showSnackbar({
	        text: startCase(response.data.message),
	        color: 'teal',
	        timeout: 2500
	      })
	    } else {
	      this.showSnackbar({
	        text: 'Please check all filed(s) requirements',
	        color: 'red',
	        timeout: 3500
	      })
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }
	}

	async showInvoice(props: any) {
	  const opts = {
	    params: {
	      include:
					'invoices.item.ref.details.ref.variation,invoices.item.ref.details.ref.images,user'
	    }
	  }

	  try {
	    const response = await OrdersService.getOneOrder(props.item.id, opts)
	    this.showSnackbar({
	      text: startCase(response.data.message),
	      color: 'teal',
	      timeout: 2500
	    })
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }
	}

	async syncCustomerOrder() {
	  try {
	    const validationResponse = await this.$validator.validateAll()
	    if (validationResponse) {
	      this.syncCustomerOrderDialog = false
	      this.showLoading({ text: 'Syncing Order...' })

	      const response = await OrdersService.syncOrder({
	        china_order_id: this.syncCustomerOrderId
	      })
	      this.getOrders()
	      this.showSnackbar({
	        text: startCase(response.data.message),
	        color: 'teal',
	        timeout: 2500
	      })
	    } else {
	      this.showSnackbar({
	        text: 'Please check all filed(s) requirements',
	        color: 'red',
	        timeout: 3500
	      })
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }
	}

	async updateTrackingCode(props: any, validationScope: string) {
	  try {
	    const validationResponse = await this.$validator.validateAll(
	      validationScope
	    )
	    if (validationResponse) {
	      props.item.trackingDialog = false
	      this.showLoading({ text: 'Saving Data...' })
	      await OrdersService.updateOrder(props.item.id, {
	        tracking_code: props.item.newTrackingCode
	      })
	      this.getOrders()
	      this.showSnackbar({
	        text: 'Changed Successfully!',
	        color: 'teal',
	        timeout: 2500
	      })
	    } else {
	      this.showSnackbar({
	        text: 'Please check all filed(s) requirements',
	        color: 'red',
	        timeout: 3500
	      })
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }
	}

	// Status color chooser
	sourceColor(source: string = '') {
	  if (snakeCase(source) === snakeCase('ALI-1')) {
	    return 'grey lighten-2 black--text'
	  } else if (snakeCase(source) === snakeCase('ALI-2')) {
	    return 'teal lighten-2 white--text'
	  } else {
	    return 'black--text'
	  }
	}

	openDeleteModal(props) {
	  this.selectedOrders = props.item
	  this.selectedDelete = props.item
	  this.showDeleteModal = true
	}

	async deleteOrder() {
	  try {
	    this.showLoading({ text: 'Saving...' })
	    this.showDeleteModal = false

	    OrdersService.deleteOrder(this.selectedOrders.id).then(response => {
	      this.showSnackbar({
	        text: 'Delete Successfully',
	        color: 'green',
	        timeout: 1500
	      })
	      this.getOrders()
	    }, error => {
	      this.showSnackbar({
	        text: 'Delete Failed',
	        color: 'red',
	        timeout: 1500
	      })
	      this.getOrders()
	    })
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.closeLoading()
	  }
	}
}

import BaseService from '@/services/BaseService'
// import orderBy = require('lodash/orderBy');

class StatusService extends BaseService {
  constructor() {
    super()
  }

  async getStatusUser(opts = {}) {
    this.endPoint = 'api/v2/statuses'
    const response = await this.get(opts)
    return response.data
  }

  async getOrderStatus(opts = {}) {
    this.endPoint = 'api/v2/statuses'
    const response = await this.get(opts)
    return response.data
  }

  async setOrderStatus(data: any, params = {}) {
    this.endPoint = 'api/v2/setstatus/order'
    return await this.post(data, params)
  }
}
export default new StatusService()